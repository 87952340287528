@@ -1,130 +0,0 @@
<template>
  <v-form>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <h3>ข้อมูลผู้ใช้งาน</h3>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col cols="4" class="mr-4">
            <v-text-field
              class="mb-4"
              v-model="search"
              dense
              hide-details
              outlined
              placeholder="ค้นหา"
            ></v-text-field>
          </v-col>
          <v-spacer></v-spacer>
          <!-- <v-col class="text-right">
            <v-btn @click="goToCreate()">สร้าง Reference Code</v-btn>
          </v-col> -->
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-card
          ><v-data-table
            :headers="headersCategory"
            :items="items"
            :search="search"
            :items-per-page="10"
            class="elevation-1"
          >
          <template v-slot:[`item.isVerify`]="{ item }">
               <span v-if="item.isVerify" style="color: #44ad43;">ยืนยันตัวตนแล้ว</span>
              <span v-if="!item.isVerify" style="color: #f00;">ยังไม่ได้ยืนยันตัวตน</span>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-row justify="center">
                <v-icon @click="viewUser(item)">mdi-eye</v-icon>
                <!-- <v-icon @click="UpdateCate(item)" class="mx-2"
                  >mdi-pencil</v-icon
                > -->
                <v-icon @click="DeleteUser(item)">mdi-delete</v-icon>

                <!-- <v-col cols="4" class="mx-0 px-1"
              ><v-btn @click="GetQuestions(item)" color="#833133" dark
                ><v-icon>mdi-magnify</v-icon></v-btn
              ></v-col
            > -->
              </v-row>
            </template>
          </v-data-table></v-card
        >
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import { Decode, Encode } from "@/services";
export default {
  data() {
    return {
      headersCategory: [
        { text: "ลำดับ", value: "count", align: "center" },
        { text: "username", value: "username", align: "center" },
        { text: "ชื่อ", value: "name", align: "center", width: 200 },
        { text: "หมายเลขโทรศัพท์", value: "tel", align: "center" },
        { text: "email", value: "email", align: "center" },
        { text: "ยืนยันตัวตน", value: "isVerify", align: "center", width: 200 },
        { text: "ประเภท", value: "type", align: "center" },
        // { text: "verify", value: "isVerify", align: "center" },
        { text: "ตัวเลือก", value: "action", align: "center", width: 200 },
      ],
// username
// password
// name
// tel
// email
// profileImg
// type
// isVerify
// idCargImg
// idCardImg2
// activeFlag
      items: [
        // { category: "promotion", description: "สินค้าจัดโปร" },
        // { category: "discount items", description: "สินค้าลดราคา" },
        // { category: "Shock price", description: "สินค้าราคาถูก" },
        // { category: "สินค้ามาใหม่", description: "สินค้ามาใหม่" },
      ],
      search: "",
      count: 0,
    };
  },
  created() {
    this.getAllUsers();
  },
  methods: {
    async getAllUsers() {
      var user = JSON.parse(Decode.decode(localStorage.getItem("userForrent")));
      const auth = {
        headers: { Authorization: `Bearer ${user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/users`,
        auth
        // 'http://localhost:8081/categories/'
      );
      console.log("users", response.data);
      this.items = response.data.data;
      for (let i in this.items) {
        this.items[i].count = parseInt(i) + 1;
      }
    },
    viewUser(val) {
      localStorage.setItem("userdata", Encode.encode(val));
    //   console.log(val);
      this.$router.push("viewUser");
    },
    async DeleteUser(val) {
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        // cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        if (result.isConfirmed) {
          var user = JSON.parse(Decode.decode(localStorage.getItem("userForrent")));
          const auth = {
            headers: { Authorization: `Bearer ${user.token}` },
          };
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/users/` + val.id,
            auth
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          // await location.reload();
          this.getAllUsers();
        }
      });
    },
    goToCreate() {
      this.$router.push("GenRefCode");
    },
  },
};
</script>